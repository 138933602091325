import React from "react"
import { Helmet } from "react-helmet"
import Header from "../components/header"
import Footer from "../components/footer"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import "../styles/style.css"
import Step1 from "../../static/images/devops/arrow_1.svg"
import Step2 from "../../static/images/devops/arrow_2.svg"
import Step3 from "../../static/images/devops/arrow_3.svg"
import Proceso  from "../../static/images/kit-digital/ciclo.svg"
import Checklist from "../../static/images/icons/web/icon27.svg"
import Cross from "../../static/images/icons/web/icon28.svg"
import Web from "../../static/images/icons/web/icon29.svg"
import Comunicaciones from "../../static/images/icons/web/icon30.svg"
import Comercio from "../../static/images/icons/web/icon31.svg"
import RRSS from "../../static/images/icons/web/icon32.svg"
import Ciberseguridad from "../../static/images/icons/web/icon33.svg"

const BonoDigital = () => {
  const data = useStaticQuery(graphql`
    query Images_bono {
      image: allFile(
        filter: { relativeDirectory: { eq: "kit-digital" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  function Bases() {window.open ("https://sede.red.gob.es/es/procedimientos/convocatoria-de-ayudas-destinadas-la-digitalizacion-de-empresas-del-segmento-i-entre");}
  function Acelera() {window.open ("https://www.acelerapyme.gob.es/user/login");}
  function Test() {window.open ("https://www.acelerapyme.gob.es/quieres-conocer-el-grado-de-digitalizacion-de-tu-pyme");}
  function Solicitud() {window.open ("https://sede.red.gob.es/es/procedimientos/convocatoria-de-ayudas-destinadas-la-digitalizacion-de-empresas-del-segmento-i-entre");}

  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Solicitar Bono Digital Kit Digital | Qualoom</title>
        <meta name="title" content="Solicitar Bono Digital Kit Digital | Qualoom"/>
        <meta name="description" content="Una ayuda del programa Kit Digital para la contratación de una o varias soluciones digitales ofrecidas por los Agentes Digitalizadores como Qualoom."/>
        <html lang='es'/>        
        <link rel="alternate" href="https://www.qualoom.es/solicitar-bono-kit-digital/"/>
        <link rel="canonical" href="https://www.qualoom.es/solicitar-bono-kit-digital/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/solicitar-bono-kit-digital/"/>
        <meta property="og:title" content="Solicitar Bono Digital Kit Digital | Qualoom"/>
        <meta property="og:description" content="Una ayuda del programa Kit Digital para la contratación de una o varias soluciones digitales ofrecidas por los Agentes Digitalizadores como Qualoom."/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3HfLvuBQ9MxEIS7c3EUWkm/0b919be153291f022b237bb833ebff63/tw_cards_kit-digital.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/solicitar-bono-kit-digital/"/>
        <meta property="twitter:title" content="Solicitar Bono Digital Kit Digital | Qualoom"/>
        <meta property="twitter:description" content="Una ayuda del programa Kit Digital para la contratación de una o varias soluciones digitales ofrecidas por los Agentes Digitalizadores como Qualoom."/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3HfLvuBQ9MxEIS7c3EUWkm/0b919be153291f022b237bb833ebff63/tw_cards_kit-digital.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/solicitar-bono-kit-digital/#breadcrumb",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://www.qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"Solicitar Bono Digital", "item": {"@id": "https://www.qualoom.es/solicitar-bono-kit-digital/", "name": "Solicitar Bono Digital"}}
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.nodes[0].childImageSharp.fluid}>
        <div className="background-kit">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Bono Digital</h1>
              <p>Una ayuda del programa Kit Digital para la contratación de una o varias soluciones digitales ofrecidas por los Agentes Digitalizadores como Qualoom.</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="services-main">
        <div className="services-main-content">
          <h2>¿Qué es el Bono Digital?</h2>
          <p>El Bono Digital es una ayuda del programa Kit Digital para la contratación de una o varias soluciones digitales ofrecidas por los Agentes Digitalizadores homologados como Qualoom.</p><br/>
          <h3>¿Qué es un Agente Digitalizador?</h3>
          <p>Un agente es aquella empresa u organización homologada por el Ministerio con capacidad de aplicar en tu organización las soluciones digitales requeridas.</p>
        </div>
      </div>
      <div className="services-main gray">
        <div className="services-main-content">
          <h2>¿Cómo solicitar el Bono Digital?</h2>
          <p>Si tu empresa cumple con todas  las condiciones establecidas en las <span className="kit-link" onClick={Bases} onKeyDown aria-hidden>bases de la convocatoria de la ayuda del Kit Digital</span>, podrás disponer de un bono digital para acceder a las distintas soluciones de digitalización. Para solicitarlo debes seguir los siguientes pasos:</p>
          <div className="kit-steps-list">
            <div className="kit-step"><img src={Step1} alt="Step 1"></img><p>Debes registrarte en <span className="kit-link" onClick={Acelera} onKeyDown aria-hidden>Acelerapyme.es</span> y completar el <span className="kit-link" onClick={Test} onKeyDown aria-hidden> test de autodiagnóstico digital</span>.</p></div>
            <div className="kit-step"><img src={Step2} alt="Step 2"></img><p>Consulta nuestro <a className="kit-link" href="/soluciones-kit-digital-qualoom/">catálogo de soluciones digitales</a> para elegir la opción que mejor se adapte a tu necesidades.</p></div>
            <div className="kit-step"><img src={Step3} alt="Step 3"></img><p>Completa el formulario para presentar tu <span className="kit-link" onClick={Solicitud} onKeyDown aria-hidden>solicitud del Bono Kit Digital</span> en la sede electrónica de Red.es. Podrás presentar tu solicitud <b>a partir del 15 de marzo hasta el 15 de septiembre de 2022</b>.<br/>Dispondrás de 6 meses para hacer uso del Bono desde el momento en el que se acepta la solicitud.</p></div>
          </div>
        </div>
      </div>
      <div className="services-main">
        <div className="services-main-content">
          <h2>Proceso de solicitud y uso del Bono Digital</h2><br/><img src={Proceso} alt="Proceso del Bono Digital"></img>
          <table className="bono">
            <tr><th className="col_1">Segmento del beneficiario</th><th className="col_2">Número de empleados</th><th className="col_3">Importe del bono</th></tr>
            <tr className="tr-1"><td className="col_1">Segmento I</td><td className="col_2">Pequeñas empresas de entre 10 y menos de 50 empleados</td><td className="col_3">12.000€</td></tr>
            <tr className="tr-2"><td className="col_1">Segmento II</td><td className="col_2">Pequeñas empresas o microempresas de entre 3 y menos de 10 empleados</td><td className="col_3">6.000€</td></tr>
            <tr className="tr-1"><td className="col_1">Segmento III</td><td className="col_2">Pequeñas empresas o microempresas de entre 0 y menos de 3 empleados</td><td className="col_3">2.000€</td></tr>
          </table>
        </div>
      </div>
      <div className="services-main gray">
        <div className="services-main-content">
          <h2>¿En qué se puede invertir el Bono Digital?</h2>
          <p>El Bono Digital se puede utilizar para <b>los gastos de las soluciones digitales ofrecidas por los agentes digitalizadores</b>, pero existen ciertos gastos para los que no se puede hacer uso del Bono Digital y deben ser abonados por la empresa.</p><br/>
          <h3>Gastos legibles</h3>
          <div className="kit-list-items">
            <div className="kit-item">
              <img src={Checklist} alt="Gasto legible"></img>
              <p>Servicios profesionales (Horas de trabajo de aplicación de la solución digital)</p>
            </div>
            <div className="kit-item">
              <img src={Checklist} alt="Gasto legible"></img>
              <p>Licencias de pago por uso</p>
            </div>
          </div><br/>
          <h3>Gastos no legibles</h3>
          <div className="kit-list-items">
            <div className="kit-item2"><img src={Cross} alt="Gasto no legible"></img><p>Impuestos</p></div>
            <div className="kit-item2"><img src={Cross} alt="Gasto no legible"></img><p>Intereses</p></div>
            <div className="kit-item2"><img src={Cross} alt="Gasto no legible"></img><p>Hardware</p></div>
            <div className="kit-item2"><img src={Cross} alt="Gasto no legible"></img><p>Gastos derivados</p></div>
            <div className="kit-item2"><img src={Cross} alt="Gasto no legible"></img><p>Gastos financieros</p></div>
            <div className="kit-item2"><img src={Cross} alt="Gasto no legible"></img><p>Contratos de conectividad a internet y comunicaciones</p></div>
          </div>
        </div>
      </div>
      <div className="services-main">
        <div className="services-main-content">
          <h2>Descubre más sobre las soluciones del Kit Digital ofrecidas por Qualoom</h2><br/>
          <div className="soluciones-list-items">
            <div className="soluciones-item"><img src={Web} alt="Sitio web y presencia en internet"></img><p>Sitio web y presencia en internet</p></div>
            <div className="soluciones-item"><img src={Comunicaciones} alt="Comunicaciones seguras"></img><p>Comunicaciones seguras</p></div>
            <div className="soluciones-item"><img src={Comercio} alt="Comercio electrónico"></img><p>Comercio electrónico</p></div>
            <div className="soluciones-item"><img src={RRSS} alt="Gestión de Redes Sociales"></img><p>Gestión de Redes Sociales</p></div>
            <div className="soluciones-item"><img src={Ciberseguridad} alt="Ciberseguridad"></img><p>Ciberseguridad</p></div>
          </div>
          <div className="bono-buttons">
            <a href="/soluciones-kit-digital-qualoom/" className="servicios-button">¿Qué soluciones digitales ofrece Qualoom?</a>
            <a href="/" className="servicios-button hide">Recursos para emprendedores<br/>y autónomos</a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default BonoDigital
